module.exports = {
  intro: {
    title: "The Perfect Place For\u00A0Your\u00A0Ideas",
    desc: "Product planing and development from the initial idea stages all the way to a winning final product. We specialize in product design, mechanical design, and electromechanical design, plastics, packaging, end-product quality product modeling, production and final ongoing\u00A0production\u00A0stages",
    addDesc: "",
    cta: "Start New Project",
  },
  whatWeDo: [
    {
      name: "plan",
      title: "Planning and Design",
      desc: "Product specification and feasibility studies, including assessment of market research, analysis of findings and high-quality product imaging, with an emphasis on user experience and user interface\u00A0(UX/UI)",
    },
    {
      name: "prot",
      title: "Prototyping",
      desc: "From imaging to production, the entire prototype construction process is conducted in our labs and workshops, which are adapted to the highest standards of product modeling, providing a solution for a broad range of engineering\u00A0fields",
    },
    {
      name: "prod",
      title: "Mass Production",
      desc: "Managing production lines, connection to manufacturing plants and workshops, quality control and product delivery to destinations in Israel and\u00A0abroad",
    },
  ],
  ourSpecial: {
    cwtitle: "Guidance and\u00A0Consulting",
    cwdesc:
      "We strive to provide consulting services throughout the entire development period, building a business plan, performing a feasibility study, and custom-tailoring the process to the entrepreneur and the\u00A0company",
    sptitle: "Patent Registration and\u00A0Editing",
    spdesc:
      "We are fully-equipped to aid in the critical patent process, including patent registration and patent editing. A patent is an idea which embodies innovation. We have the experience to navigate this process in order to receive credit which protects the patent owner and prevents its use by\u00A0competitors",
    qctitle: "Quality Control",
    qcdesc:
      "We offer clients a complete, immediate, and efficient response. We never outsource work to any third-party companies, instead applying all the knowledge and experience we have accumulated to the extensive and rigorous process, ensuring that ultimately the client leaves satisfied that meticulous quality-control practices have been applied at every stage of the product development\u00A0process",
    ittitle: "Finding Investors",
    itdesc:
      "Investments have long been vital to our operations. For years, we have specialized in this complex process, so crucial to a project’s success. Just a few of the critical components in this area in which we provide services include crowdfunding, one-pagers, investor presentations, aid in enlisting investors, investor meeting preparation, and\u00A0more",
  },
  clients: {
    title: "Clients & Cooperations",
  },
  dir: "ltr",
};
