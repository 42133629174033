module.exports = {
  seo: {
    title: "Jenko - Recommendations",
    description: "",
  },
  articles: {
    title: "Publications",
    articlesList: [
      {
        title: "From Vision to Reallity",
        desc: "Metsuda - Security Magazine of Israel",
        date: "May 2021",
        label: "metsuda",
        url: "jenko-metsuda-article.pdf",
      },
      {
        title: "“From Bartender to CEO”",
        desc: null,
        date: "The Open University of  Israel - April2019",
        label: "lecture",
        url: "https://youtu.be/5R4MyvCIn2I",
      },
    ],
  },
  recommends: {
    title: "Clients Recommends",
    recommendsList: [
      {
        label: "amitgreen",
        name: "Amit Green",
        role: "Private Entrepreneur",
        text: "“I had some ideas in my head for a while.  When I came to Jenko, I realized I’d found the perfect place for my ideas. At our first meeting, we discussed risk management, goal-setting, and my expectations for the whole process from the idea to the final product. I’ve found a company that was able to take my raw idea and develop it into a final product with the potential to succeed.  And they did it all with caring, out-of-the-box thinking, dedication, and uncompromising professionalism. I strongly recommend that every entrepreneur, company, and business start their project by meeting with Jenko.  I’m absolutely sure that, like me and so many others, you’ll know what I mean when I say I felt completely at home\u00A0with\u00A0them.”",
      },
      {
        label: "talhadas",
        name: "Tal Hadas",
        role: "VP Marketing, CATHETRIX",
        text: "“After several attempts to develop a medical product with a number of different companies, we arrived at Jenko Engineering. Already at the first meeting there was an understanding of the product vision and we realized that we had found the company we were looking for. Helpfulness, a powerful mind, speed and personal attention, were felt throughout the duration of the work In front of them. After about a month and a half, we were presented with a final prototype and from there the road to a successful pilot was already short. We highly recommend Jenko Engineering and look forward to continued joint work in the near future on new and\u00A0innovative\u00A0projects.”",
        textMain:
          "After several attempts to develop a medical product with a number of different companies, we arrived at Jenko Engineering. Already at the first meeting there was an understanding of the product vision and we realized that we had found the company we were looking for. Helpfulness, a powerful mind, speed and personal attention, were felt throughout the duration of the work In front of them. After about a month and a half, we were presented with a final prototype and from there the road to a successful pilot was already short. We highly recommend Jenko Engineering and look forward to continued joint work in the near future on new and\u00A0innovative\u00A0projects.",
      },
      {
        label: "denedict",
        name: "Dr. Yeshayahu Benedict",
        role: "CEO & owner of Benedict Orthopedics,\u00A0Inc.",
        text: "“After registering a worldwide patent for a bone-restoring prosthesis, I met unsuccessfully with a few medical-equipment companies to try to have them build a prototype. After about a year and a half I found Jenko, and within just two weeks, they managed to build a prototype with a tiny, brilliant mechanism! At that moment, I realized the true meaning of out-of-the-box thinking. Thanks to Jenko, I’m looking forward to reaching patients all\u00A0over\u00A0the\u00A0world.”",
      },
    ],
  },
  dir: "ltr",
};
