import * as React from "react";
import Layout from "../layouts/en";
import {
  intro,
  whatWeDo,
  ourSpecial,
  clients,
  dir
} from "../data/content/index/index-en";
import { recommends } from "../data/content/recommends/recommends-en";
import {
  Intro,
  WhatWeDo,
  OurSpecialties,
  Clients,
  Testimonial,
} from "../data/components/homepage";
import * as styles from "../styles/pages/index/index.module.css";

const IndexPage = (props) => {
  const { sIntroSection, sIntro, sIntroDesc } = styles;
  return (
    <Layout location={props.location} lang="en">
      <article lang="en" dir="ltr">
        <Intro content={intro} styles={{ sIntroSection, sIntro, sIntroDesc }} />
        <WhatWeDo content={whatWeDo} dir={dir} />
        <OurSpecialties content={ourSpecial} />
        <Clients content={clients} />
        <Testimonial content={recommends.recommendsList[1]} />
      </article>
    </Layout>
  );
};

export default IndexPage;
